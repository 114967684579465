export class OrderManager {
  static orders;

  constructor(orders, isUrgent = false) {
    this.buildOrders(orders, isUrgent);
  }

  buildOrders(orders, isUrgent) {
    let newOrders = [];
    const combinations = this.formatOrders(orders.combinations, true, isUrgent);
    const singles = this.formatOrders(orders.single_services, false, isUrgent);
    newOrders.push(...combinations.concat(singles));
    if (isUrgent) {
      this.orders = newOrders;
    } else {
      this.orders = { exported: [], confirmed: [], nonConfirmed: [] };
      this.orders.confirmed = newOrders.filter(
        (order) => order.confirmed && !order.exported
      );
      this.orders.nonConfirmed = newOrders.filter(
        (order) => !order.exported && !order.confirmed
      );
      this.orders.exported = newOrders.filter((order) => order.exported);
    }
  }

  formatOrders(orders, combined, isUrgent) {
    return orders.map((order) => {
      const formattedOrder = {
        type: "",
        import: {},
        export: {},
        status: "ok",
        confirmed: false,
        exported: false,
        pending: false,
        flag_extra_day: false,
        id: "",
      };
      formattedOrder.type = combined ? "combined" : order.type.toLowerCase();
      formattedOrder.confirmed = order.confirmed;
      formattedOrder.exported = order.exported;
      formattedOrder.flag_extra_day = order.flag_extra_day || false;

      if (combined) {
        formattedOrder.pending =
          order.flag_break ||
          order.flag_change_import ||
          order.flag_change_export ||
          order.flag_route_change;
        formattedOrder.import = {
          client_address: order.import_factory_address,
          container_type: order.import_container_type,
          depot: order.import_depot_name,
          id_depot: order.import_depot_id,
          id_port: order.import_id_port,
          id_management_area: order.import_management_area_id,
          id: order.import_service_id,
          commercial_number: order.import_commercial_number,
          km: order.base_km - order.km,
          savings_percentage: parseFloat(
            (((order.base_km - order.km) / order.base_km) * 100).toFixed(2)
          ),
          client: order.import_factory_name,
          postal_code_client: order.import_factory_postal_code,
          service_datetime: new Date(
            order.import_service_datetime
          ).toLocaleString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          datetime_container: new Date(order.datetime_start).toLocaleString(
            "es-ES",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
          fabric_datetime: new Date(order.import_end).toLocaleString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          client_town: order.import_factory_town,
          supplier: order.supplier_name,
          supplier_id: order.id_supplier,
          kpi: order.kpi?.toFixed(2),
          tilt: order.import_tilt,
          reinforced: order.import_need_reinforced,
          multi_step: order.import_multi_step,
        };

        if (isUrgent) {
          formattedOrder.import.single_end = new Date(
            order.import_single_end
          ).toLocaleString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          });
        }

        formattedOrder.export = {
          client_address: order.export_factory_address,
          container_type: order.export_container_type,
          depot: order.export_depot_name,
          id_depot: order.export_depot_id,
          id_port: order.export_id_port,
          id_management_area: order.export_management_area_id,
          id: order.export_service_id,
          commercial_number: order.export_commercial_number,
          km: order.base_km - order.km,
          client: order.export_factory_name,
          postal_code_client: order.export_factory_postal_code,
          service_datetime: new Date(
            order.export_service_datetime
          ).toLocaleString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
          datetime_container: new Date(order.datetime_end).toLocaleString(
            "es-ES",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
          fabric_datetime: new Date(order.export_start).toLocaleString(
            "es-ES",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
          client_town: order.export_factory_town,
          supplier: order.supplier_name,
          supplier_id: order.id_supplier,
          kpi: order.kpi?.toFixed(2),
          tilt: order.export_tilt,
          reinforced: order.export_need_reinforced,
          multi_step: order.export_multi_step,
        };
      } else {
        formattedOrder.pending = order.flag_change;
        const datetime_container =
          order.type === "IMPORT" ? order.datetime_start : order.datetime_end;
        const fabric_datetime =
          order.type === "IMPORT" ? order.import_end : order.export_start;
        formattedOrder[order.type.toLowerCase()] = {
          client_address: order.factory_address,
          container_type: order.container_type,
          depot: order.depot_name,
          id_depot: order.id_depot,
          id_port: order.id_port,
          id_management_area: order.id_management_area,
          id: order.id,
          commercial_number: order.commercial_number,
          client: order.factory_name,
          postal_code_client: order.factory_postal_code,
          service_datetime: new Date(order.service_datetime).toLocaleString(
            "es-ES",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
          datetime_container:
            datetime_container === null
              ? null
              : new Date(datetime_container).toLocaleString("es-ES", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
          datetime_container_end:
            order.datetime_end === null
              ? null
              : new Date(order.datetime_end).toLocaleString("es-ES", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
          datetime_container_start:
            order.datetime_start === null
              ? null
              : new Date(order.datetime_start).toLocaleString("es-ES", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
          fabric_datetime:
            fabric_datetime === null
              ? null
              : new Date(fabric_datetime).toLocaleString("es-ES", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
          single_end: null,
          client_town: order.factory_town,
          supplier: order.supplier_name,
          supplier_id: order.id_supplier,
          kpi: order.kpi?.toFixed(2),
          tilt: order.tilt,
          reinforced: order.need_reinforced,
          dangerous: order.is_dangerous,
          is_flexibag: order.is_flexibag,
          multi_step: order.multi_step,
          shipper_own: order.shipper_own,
        };
      }

      formattedOrder.id = combined
        ? formattedOrder.import.id
        : formattedOrder[order.type.toLowerCase()].id;
      formattedOrder.supplier_id = order.id_supplier;
      return formattedOrder;
    });
  }

  getOrderIds() {
    let orderIds = [];
    this.orders.exported.forEach((order) => {
      if (order.type === "combined") {
        orderIds.push(order.import.id);
        orderIds.push(order.export.id);
      } else {
        orderIds.push(order[order.type.toLowerCase()].id);
      }
    });
    this.orders.nonConfirmed.forEach((order) => {
      if (order.type === "combined") {
        orderIds.push(order.import.id);
        orderIds.push(order.export.id);
      } else {
        orderIds.push(order[order.type.toLowerCase()].id);
      }
    });
    this.orders.confirmed.forEach((order) => {
      if (order.type === "combined") {
        orderIds.push(order.import.id);
        orderIds.push(order.export.id);
      } else {
        orderIds.push(order[order.type.toLowerCase()].id);
      }
    });
    return [...new Set(orderIds)]; //For non duplicates
  }

  getCommercialNumbers() {
    let commercialNumber = [];
    this.orders.exported.forEach((order) => {
      if (order.type === "combined") {
        commercialNumber.push(order.import.commercial_number);
        commercialNumber.push(order.export.commercial_number);
      } else {
        commercialNumber.push(
          order[order.type.toLowerCase()].commercial_number
        );
      }
    });
    this.orders.nonConfirmed.forEach((order) => {
      if (order.type === "combined") {
        commercialNumber.push(order.import.commercial_number);
        commercialNumber.push(order.export.commercial_number);
      } else {
        commercialNumber.push(
          order[order.type.toLowerCase()].commercial_number
        );
      }
    });
    this.orders.confirmed.forEach((order) => {
      if (order.type === "combined") {
        commercialNumber.push(order.import.commercial_number);
        commercialNumber.push(order.export.commercial_number);
      } else {
        commercialNumber.push(
          order[order.type.toLowerCase()].commercial_number
        );
      }
    });
    return [...new Set(commercialNumber)]; //For non duplicates
  }

  getUrgentOrderIds() {
    let orderIds = [];
    this.orders.forEach((order) => {
      if (order.type === "combined") {
        orderIds.push(order.import.id);
        orderIds.push(order.export.id);
      } else {
        orderIds.push(order[order.type.toLowerCase()].id);
      }
    });

    return [...new Set(orderIds)]; //For non duplicates
  }

  getUrgentCommercialNumbers() {
    let commercialNumber = [];
    this.orders.forEach((order) => {
      if (order.type === "combined") {
        commercialNumber.push(order.import.commercial_number);
        commercialNumber.push(order.export.commercial_number);
      } else {
        commercialNumber.push(
          order[order.type.toLowerCase()].commercial_number
        );
      }
    });
    return [...new Set(commercialNumber)]; //For non duplicates
  }
  async getOrderSupplierFromId(orderId, supplierId) {
    let suppliers = await Promise.all([
      this.$store.dispatch("GET_SUPPLIERS_FOR_ORDER", {
        orderId: orderId,
      }),
    ]);
    suppliers = suppliers.flat();
    const supplier = suppliers.find((supplier) => supplier.id === supplierId);
    return supplier.name;
  }
}
